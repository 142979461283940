import React, { useState } from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import logo from '~/assets/logo_header_neg-2x.png';
import PDF from '~/pages/Catalogo/catalogo.pdf';
import api from '../../services/api';

import GDPRPanel from '~/components/GDPRPanel';
import WhastFooter from '~/components/WhastFooter';
import Tiktok from '~/assets/tiktok-black.png';

function Footer() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  let button;
  if (loading) {
    button = (
      <div className="btn mb-2">
        <div className="loader" />
      </div>
    );
  } else {
    button = (
      <button type="submit" className="btn mb-2">
        Enviar
      </button>
    );
  }

  const handleSubmit = async event => {
    setLoading(true);
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const response = await api.post('/contato', formData);
    setLoading(false);
    form.reset();
    if (response.status === 200) {
      const messageDiv = (
        <div className="alert alert-success">Mensagem enviada com sucesso!</div>
      );
      setMessage(messageDiv);
    } else {
      const messageDiv = (
        <div className="alert alert-danger">Erro ao enviar a mensagem!</div>
      );
      setMessage(messageDiv);
    }
  };

  return (
    <>
      <WhastFooter />
      <Container>
        <div className="container fundo p-0">
          <nav className="navbar navbar-expand-md py-2 desk-only col">
            <ul className="navbar-nav justify-content-between p-0 w-100">
              <li className="nav-item">
                <Link to={`${process.env.PUBLIC_URL}/`} className="nav-link">
                  HOME
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`${process.env.PUBLIC_URL}/empresa-de-cosmeticos`}
                  className="nav-link"
                >
                  QUEM SOMOS
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`${process.env.PUBLIC_URL}/produtos`}
                  className="nav-link"
                >
                  PRODUTOS
                </Link>
              </li>
              <li className="nav-item">
                <a
                  href={PDF}
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link m-auto"
                >
                  CATÁLOGO
                </a>
              </li>
              <li className="nav-item">
                <Link
                  to={`${process.env.PUBLIC_URL}/onde-encontrar`}
                  className="nav-link"
                >
                  ONDE ENCONTRAR
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={`${process.env.PUBLIC_URL}/contato`}
                  className="nav-link"
                >
                  CONTATO
                </Link>
              </li>
            </ul>
          </nav>
          <div className="d-flex px-2">
            <div className="col coluna-contato">
              <div className="">
                <img src={logo} alt="Logo Anaconda" className="my-4 logo" />
                <p className="text-justify desc-anaconda">
                  A Anaconda Cosméticos é uma empresa 100% nacional com
                  tecnologias francesas, alemãs e americanas de alta performance
                  e distribuição em todo território nacional.
                </p>
              </div>
              <div className=" formas-contato">
                <h6 className="col-12 p-0 m-0">Endereço</h6>
                <p>
                  Av. Celso Garcia, 676 <br /> São Paulo – SP
                </p>
                <h6 className="col-12 p-0 m-0">Telefone</h6>
                <a href="tel:+551126187766" className="text-black">
                  {' '}
                  011 – 2618-7766
                </a>
                <h6 className="col-12 p-0 m-0">Email</h6>
                <a
                  href="mailto:fabrica.anaconda@gmail.com"
                  className="text-break text-black"
                >
                  {' '}
                  fabrica.anaconda@gmail.com
                </a>
              </div>
              <div className="">
                <a href="https://www.tiktok.com/@anaconda.cosmeticos">
                  <img src={Tiktok} alt="tiktok" style={{ marginRight: '10px', width: "24px" }} />
                </a>
                <a
                  href="https://www.facebook.com/Anaconda-Cosm%C3%A9ticos-109160597287290/"
                  className=""
                >
                  <FaFacebookF size={24} color="#000" />
                </a>
                <a
                  href="https://www.instagram.com/anaconda.cosmeticosoficial"
                  className=" ml-4"
                >
                  <FaInstagram size={24} color="#000" />
                </a>
              </div>
            </div>
            <div className="col-7 ml-auto desk-only">
              <h4 className="my-4 ml-0">CONTATO</h4>

              <div className="container p-0">
                <form
                  onSubmit={handleSubmit}
                  id="form-contato"
                  encType="multipart/form-data"
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control  my-4"
                      id="nome"
                      placeholder="Digite seu nome"
                      name="nome"
                      required
                    />
                  </div>
                  <div className="form-group ">
                    <input
                      type="email"
                      className="form-control  my-4"
                      id="email"
                      placeholder="Seu email"
                      name="email"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control  my-4"
                      id="duvida"
                      rows="3"
                      placeholder="Qual é a sua dúvida?"
                      name="mensagem"
                      required
                    />
                  </div>
                  {button}
                </form>
                {message}
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <small>@ ANACONDA COSMÉTICOS | TODOS OS DIREITOS RESERVADOS</small>
          </div>
        </div>
      </Container>
      <GDPRPanel />
    </>
  );
}

export default Footer;
